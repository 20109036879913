.server-index .server-type {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.server-index .server-type .item {
  width: 33.333%;
  float: left;
  text-align: center;
  font-size: 0.28rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.server-index .server-type .item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background-color: var(--MainColor);
  opacity: 0;
}
.server-index .server-type .item.active {
  font-size: 0.3rem;
  color: #000;
}
.server-index .server-type .item.active:after {
  opacity: 1;
}
.server-index .server-body {
  margin-top: 40px;
}
.server-index .server-body-item {
  overflow: hidden;
  display: none;
  background: #f5f6f8;
}
.server-index .server-body-item.active {
  display: block;
}
.server-index .server-time {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 40px + 40px);
  left: 0;
  right: 0;
  height: 40px;
  padding: 0 10px;
  background-color: #f5f6f8;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-items: center;
}
.server-index .server-time .item {
  flex-shrink: 0;
  margin-left: 10px;
  background-color: #eee;
  padding: 3.5px 6.5px;
  border-radius: 12px;
}
.server-index .server-time .item.active {
  background-color: var(--MainColor);
  color: #fff;
}
.server-index .server-time .item:first-child {
  margin-left: 0;
}
.server-index .server-groups {
  padding: 0 10px 10px 10px;
}
.server-index .server-group {
  margin-top: 10px;
}
.server-index .server-group .group-title {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
}
.server-index .server-group .group-title .icon {
  font-size: 18px;
  margin-right: 5px;
  color: var(--MainColor);
  width: 18px;
}
.server-index .server-item {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.server-index .server-item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.server-index .server-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.server-index .server-item .text {
  flex: 1;
  width: 0;
  font-size: 0.23rem;
  color: #999;
  line-height: 1.7;
  display: flex;
  flex-direction: column;
}
.server-index .server-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.29rem;
  color: #282828;
  display: flex;
  align-items: center;
}
.server-index .server-item .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.server-index .server-item .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.3;
}
.server-index .server-item .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}
.server-index .server-item .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.065rem;
  margin-top: 0.065rem;
  z-index: -1;
}
.server-index .server-item .info1 .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.server-index .server-item .info2 {
  display: flex;
  align-items: center;
  color: #FF5E00;
}
.server-index .server-item .info2 .server {
  color: #FF5E00;
  margin-right: 10px;
}
.server-index .server-item .info2 .time {
  color: #fff;
  margin-right: 10px;
  background: #FF446A;
  border-radius: 6px;
  padding: 0 5px;
}
.server-index .server-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.server-index .server-item-div {
  background: #fff;
  border-radius: 8px;
  margin-top: 0.3rem;
}
.server-index .server-item-div .server-item {
  margin-top: 0;
}
.server-index .server-tag {
  position: fixed;
  z-index: 555;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.server-index .server-tag .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.server-index .server-tag .container {
  position: fixed;
  z-index: 777;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.server-index .server-tag.enter-active .mask,
.server-index .server-tag.enter-done .mask {
  opacity: 1;
}
.server-index .server-tag.enter-active .container,
.server-index .server-tag.enter-done .container {
  opacity: 1;
  transform: translateY(0);
}
.server-index .server-tag.exit-active .mask {
  opacity: 0;
}
.server-index .server-tag.exit-active .container {
  opacity: 0;
  transform: translateY(100%);
}
.server-index .server-tag .title {
  font-size: 0.28rem;
  color: #000;
}
.server-index .server-tag .tags {
  margin-left: -5px;
  margin-right: -5px;
}
.server-index .server-tag .tag {
  margin-top: 0;
  float: left;
  width: 25%;
  padding: 10px;
  color: #000;
}
.server-index .server-tag .tag div {
  background-color: #eee;
  padding: 5px;
  text-align: center;
}
.server-index .server-tag .tag.active {
  color: #fff;
}
.server-index .server-tag .tag.active div {
  background-color: var(--MainColor);
}
.server-index .server-today {
  margin-top: 40px;
}
