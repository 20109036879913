:root {
  --MainColor: #eb424c;
}
.trade-confirm {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 3s;
}
.trade-confirm:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 901;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
  opacity: 0;
}
.trade-confirm .container {
  position: relative;
  z-index: 902;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff center top no-repeat;
  background-size: contain;
  transition: all 0.25s;
  opacity: 0;
  transform: scale(0.75);
}
.trade-confirm.appear-active:before,
.trade-confirm.appear-done:before,
.trade-confirm.enter-active:before,
.trade-confirm.enter-done:before {
  opacity: 1;
}
.trade-confirm.appear-active .container,
.trade-confirm.appear-done .container,
.trade-confirm.enter-active .container,
.trade-confirm.enter-done .container {
  opacity: 1;
  transform: scale(1);
}
.trade-confirm.exit-active:before {
  opacity: 0;
}
.trade-confirm.exit-active .container {
  opacity: 0;
  transform: scale(0.75);
}
.trade-confirm .head {
  color: #fff;
  padding: 15px;
  text-align: center;
}
.trade-confirm .head .title {
  font-weight: bold;
  font-size: 0.3rem;
}
.trade-confirm .head .info {
  font-size: 0.26rem;
  margin-top: 5px;
}
.trade-confirm .main {
  flex: 1;
  height: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.trade-confirm .main .title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.28rem;
}
.trade-confirm .main .title img {
  height: 20px;
  margin-right: 5px;
}
.trade-confirm .process {
  padding: 10px 0;
}
.trade-confirm .process img {
  display: block;
  width: 85%;
  margin: 0 auto;
}
.trade-confirm .process ul {
  margin-top: 10px;
}
.trade-confirm .process ul li {
  float: left;
  width: 25%;
  text-align: center;
}
.trade-confirm .body {
  flex: 1;
  height: 0;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.trade-confirm .body p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
}
.trade-confirm .body p:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffc000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.trade-confirm .operate .input {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.trade-confirm .operate .input .agreement {
  margin-right: 5px;
  border: 2px solid #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: -0.5px;
  position: relative;
}
.trade-confirm .operate .input .agreement.checked {
  border: 2px solid var(--MainColor);
}
.trade-confirm .operate .input .agreement.checked:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--MainColor);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.trade-confirm .operate .input input {
  margin-right: 10px;
}
.trade-confirm .operate .buy {
  display: block;
  background-color: var(--MainColor);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
  transition: all 0.25s;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.trade-confirm .operate .buy.disable {
  background-image: linear-gradient(#eee, #eee);
}
.trade-detail {
  padding-bottom: calc(env(safe-area-inset-bottom) + 47.5px);
  background: #f5f5f8;
  padding: 10px;
}
.trade-detail .trade-game {
  padding: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  border-radius: 8px;
  background: #fff;
}
.trade-detail .trade-game .icon {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  overflow: hidden;
  margin-right: 0.19rem;
}
.trade-detail .trade-game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.trade-detail .trade-game .text {
  flex: 1;
  width: 0;
}
.trade-detail .trade-game .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: var(--MainColor);
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.trade-detail .trade-game .name {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  display: flex;
  align-items: center;
}
.trade-detail .trade-game .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.trade-detail .trade-game .name .nameRemark {
  font-size: 12px;
  display: inline;
  color: #999;
  background-color: #f3f4f8;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: normal;
  vertical-align: text-bottom;
  line-height: 17px;
}
.trade-detail .trade-game .info {
  font-size: 0.26rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.trade-detail .trade-game .info .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.trade-detail .trade-game .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #666;
}
.trade-detail .trade-game .info2 .tag {
  display: inline-block;
  padding-right: 5px;
  color: #FAB72B;
}
.trade-detail .trade-game .info2 .tag:first-child {
  color: #FF4200;
}
.trade-detail .trade-game .info2 .tag:nth-child(2) {
  color: #75D100;
}
.trade-detail .trade-game .info2 .tag:last-child {
  color: #2893FF;
}
.trade-detail .trade-item {
  padding: 12.5px 0;
}
.trade-detail .trade-item:last-child {
  padding-bottom: 65px;
}
.trade-detail .trade-item .head {
  color: #282828;
  font-size: 0.32rem;
  font-weight: bold;
}
.trade-detail .trade-item .head span {
  color: red;
  font-size: 0.28rem;
}
.trade-detail .trade-item .body {
  margin-top: 10px;
}
.trade-detail .trade-item .body.base {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12.5px;
  border-radius: 8px;
}
.trade-detail .trade-item .body.base .info {
  flex: 1;
  position: relative;
}
.trade-detail .trade-item .body.base .info .item {
  margin: 5px auto;
}
.trade-detail .trade-item .body.base .price {
  color: #FA3932;
  font-size: 0.3rem;
}
.trade-detail .trade-item .body.base .coin {
  color: #FA3932;
  font-size: 0.24rem;
  margin-left: 0.2rem;
}
.trade-detail .trade-item .body.desc {
  padding-bottom: 10px;
  background: #fff;
  padding: 12.5px;
  border-radius: 8px;
}
.trade-detail .trade-item .body.imgs .img {
  display: block;
  float: left;
  width: 33.333%;
  padding-right: 12.5px;
  padding-top: 12.5px;
}
.trade-detail .trade-item .body.imgs .img .img {
  display: block;
  width: 100%;
}
.trade-detail .trade-item .body.imgs .img img {
  height: 95px;
  object-fit: cover;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
}
.trade-detail .trade-item:last-child .body {
  margin-top: 0;
}
.trade-detail .trade-desc {
  background-color: pink;
  color: indianred;
  padding: 5px 15px;
  position: relative;
}
.trade-detail .trade-state {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-60%);
}
.trade-detail .trade-state img {
  display: block;
  width: 75px;
}
.trade-detail .trade-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 5px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  display: flex;
}
.trade-detail .trade-operate .favorite {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  transition: all 0.1s;
  justify-content: center;
  font-size: 0.2rem;
}
.trade-detail .trade-operate .favorite .favorite {
  display: block;
}
.trade-detail .trade-operate .favorite .favorited {
  display: none;
}
.trade-detail .trade-operate .favorite.active .favorite {
  display: none;
}
.trade-detail .trade-operate .favorite.active .favorited {
  display: block;
}
.trade-detail .trade-operate .favorite img {
  width: 19px;
  margin: 0 auto;
}
.trade-detail .trade-operate .btn {
  flex: 1;
  margin: 0 5px;
  background-color: var(--MainColor);
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.28rem;
  border-radius: 20px;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.nav-view-head .operate .trade-operates .anticon {
  font-size: 22px;
  margin-left: 10px;
}
.trade-index {
  background-color: #f5f6f8;
}
.trade-index .trade-head {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding-top: env(safe-area-inset-top);
  background-color: #fff;
  color: #282828;
}
.trade-index .trade-head .background {
  opacity: 0;
  position: absolute;
  z-index: 1;
  inset: 0px;
  background-color: #fff;
  transition: all 0.1s ease 0s;
}
.trade-index .trade-head .container {
  position: relative;
  z-index: 560;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: all 0.1s;
}
.trade-index .trade-menu .items {
  display: flex;
  width: 95%;
  margin: 0 auto;
  border-radius: 15px;
}
.trade-index .trade-menu .item {
  flex: 1;
  margin: 5px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #282828;
  font-size: 0.26rem;
  position: relative;
}
.trade-index .trade-menu .item img {
  display: block;
  height: 30px;
  margin-bottom: 2.5px;
}
.trade-index .trade-menu .item .tips {
  position: absolute;
  top: -10px;
  right: 0;
}
.trade-index .trade-menu .item .tips img {
  height: 50px;
}
.trade-index .trade-inside-menu {
  padding-top: 10px;
}
.trade-index .trade-screen-menu {
  padding-top: calc(env(safe-area-inset-top) + 50px);
}
.trade-index .trade-body {
  padding: 10px;
}
.trade-index-notice {
  padding: 5px;
}
.trade-index-good .title {
  display: flex;
  align-items: center;
}
.trade-index-good .title .text {
  flex: 1;
  font-size: 0.34rem;
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.trade-index-good .title .text img {
  height: 15px;
  margin: 0 5px;
}
.trade-index-good .title .more {
  font-size: 13px;
}
.trade-index-good .items {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  margin: 0 -5px;
}
.trade-index-good .items .item {
  width: 60%;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
}
.trade-index-good .items .item .icon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
}
.trade-index-good .items .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.trade-index-good .items .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  line-height: 1.7;
}
.trade-index-good .items .item .title {
  font-size: 0.28rem;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
}
.trade-index-good .items .item .name {
  color: #FF5E00;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.22rem;
}
.trade-index-good .items .item .price {
  color: #FF5E00;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-index-good .items .item .price span {
  font-size: 0.22rem;
}
.trade-list {
  min-height: 100vh;
}
.trade-list .list-head-place {
  height: 0;
}
.trade-list .list-head-place.active {
  height: 50px;
}
.trade-list .list-head {
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
}
.trade-list .list-head.fixed {
  position: fixed;
  z-index: 556;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  padding: 0 10px;
  background-color: #fff;
}
.trade-list .list-head .sort {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  font-size: 0.2rem;
  display: flex;
  align-items: center;
}
.trade-list .list-head .sort .title {
  font-size: 0.34rem;
}
.trade-list .list-head .sort .icon {
  margin: 0 5px;
}
.trade-list .list-head .sorts {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 0 3px #eee;
  transition: all 0.25s;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
}
.trade-list .list-head .sorts.enter-active,
.trade-list .list-head .sorts.enter-done {
  opacity: 1;
  transform: scaleY(1);
  z-index: 1;
}
.trade-list .list-head .sorts.exit-active {
  opacity: 0;
  transform: scaleY(0);
}
.trade-list .list-head .sorts .item {
  padding: 5px;
  font-size: 0.28rem;
  color: #000;
}
.trade-list .list-head .sorts .item.active {
  color: var(--MainColor);
}
.trade-list .list-head .filter {
  color: #000;
  font-size: 0.34rem;
  margin-right: 10px;
  width: 26px;
}
.trade-list .list-head .input {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #eee;
  border-radius: 20px;
  padding: 7.5px 12.5px;
  font-size: 0.24rem;
}
.trade-list .list-head .input input {
  flex: 1;
  background: none;
  border: none;
  width: 80%;
}
.trade-list .list-head .input .icon {
  width: 16px;
  margin-left: 0.1rem;
}
.trade-list .list-head .switch-img {
  margin-left: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.trade-list .list-head .switch-img img {
  width: 18px;
}
.trade-list .list-select {
  padding-bottom: 0.12rem;
}
.trade-list .list-select.fixed {
  position: fixed;
  z-index: 555;
  top: calc(env(safe-area-inset-top) + 90px);
  left: 0;
  right: 0;
  padding: 0 10px;
  background-color: #fff;
  height: 50px;
}
.trade-list .list-select .items {
  align-items: center;
  overflow: scroll;
  text-align: justify;
  display: flex;
}
.trade-list .list-select .items .item {
  width: 22%;
  background: #fff;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 5px 0px;
  background-size: 100% 100%;
  border-radius: 50px;
  color: #282828;
  border: 1px solid #ccc;
  font-size: 0.26rem;
  flex-shrink: 0;
}
.trade-list .list-select .items .item.active {
  background: var(--MainColor);
  color: #fff;
  border: 0;
}
.trade-list .list-select .items .item:first-child {
  margin-left: 0;
}
.trade-list .list-select .items::-webkit-scrollbar {
  display: none;
}
.trade-list .list-body {
  margin-top: 5px;
  background: #f5f6f8;
}
.trade-list .list-body .item {
  background-color: #fff;
  padding: 10px;
  display: block;
  margin-top: 10px;
  border-radius: 8px;
}
.trade-list .list-body .item:first-child {
  margin-top: 0;
}
.trade-list .list-body .main .icon {
  width: 130px;
  height: 75px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.trade-list .list-body .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.trade-list .list-body .main .icon .coin {
  position: absolute;
  top: 0;
  right: 0;
  background: #FF6C6C;
  border-radius: 0px 8px 0px 8px;
  width: auto;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  padding: 0.03rem 0.1rem;
}
.trade-list .list-body .main .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.trade-list .list-body .main .info .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: bold;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.3;
  width: max-content;
  margin: 5px 0px;
}
.trade-list .list-body .main .info .nameRemark-new-info {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  width: max-content;
  flex-shrink: 0;
}
.trade-list .list-body .main .info .name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-list .list-body .main .info .name .type {
  background-color: var(--MainColor);
  border-radius: 2px;
  padding: 0px 3px;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
  display: inline;
}
.trade-list .list-body .main .info .name .type-1:after {
  content: 'BT版';
}
.trade-list .list-body .main .info .name .type-2:after {
  content: '折扣版';
}
.trade-list .list-body .main .info .name .type-3:after {
  content: 'H5版';
}
.trade-list .list-body .main .info .name .type-4:after {
  content: 'GM版';
}
.trade-list .list-body .main .info .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.trade-list .list-body .main .info .name .originly {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-list .list-body .main .info .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: bold;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.34;
  margin: 0;
}
.trade-list .list-body .main .info .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  font-weight: normal;
}
.trade-list .list-body .main .info .name .ios {
  font-size: 16px;
  color: #ADDE74;
  margin-left: 2.5px;
  display: inline;
}
.trade-list .list-body .main .info .name .android {
  font-size: 16px;
  color: #76AAF7;
  margin-left: 2.5px;
  display: inline;
}
.trade-list .list-body .main .info .title {
  margin-top: 10px;
  font-size: 0.24rem;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-list .list-body .main .info .title .ios {
  font-size: 16px;
  color: #666666;
  margin-right: 2.5px;
  display: inline;
}
.trade-list .list-body .main .info .title .android {
  font-size: 16px;
  color: green;
  margin-right: 2.5px;
  display: inline;
}
.trade-list .list-body .main .info .title-nameremark {
  margin-top: 0;
}
.trade-list .list-body .data {
  display: flex;
  align-items: center;
  position: relative;
  clear: both;
}
.trade-list .list-body .data .time {
  flex: 1;
  width: 0;
  color: #999;
  font-size: 12px;
  padding-top: 10px;
}
.trade-list .list-body .data .prices {
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.trade-list .list-body .data .prices .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  text-align: end;
}
.trade-list .list-body .data .prices .price {
  color: #FF5E00;
  font-size: 0.28rem;
}
.trade-list .list-body .item-multiple {
  padding: 0 0 5px;
  width: 48%;
  float: left;
  margin-right: 4%;
  background: #fff;
  margin-bottom: 0.2rem;
  border-radius: 8px;
}
.trade-list .list-body .item-multiple .main {
  display: block;
  margin-bottom: -3px;
}
.trade-list .list-body .item-multiple .main .icon {
  width: 100%;
  height: 95px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.trade-list .list-body .item-multiple .main .icon img {
  width: 100%;
  margin: 0 auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  object-fit: cover;
}
.trade-list .list-body .item-multiple .main .icon .coin {
  position: absolute;
  top: 0;
  right: 0;
  background: #FF6C6C;
  border-radius: 0px 8px 0px 8px;
  width: auto;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  padding: 0.03rem 0.1rem;
}
.trade-list .list-body .item-multiple .main .info {
  padding: 8px;
  line-height: 1.7;
}
.trade-list .list-body .item-multiple .main .info .name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-list .list-body .item-multiple .main .info .name .type {
  display: inline;
}
.trade-list .list-body .item-multiple .main .info .title {
  display: block;
  margin-top: 5px;
}
.trade-list .list-body .item-multiple .main .info .title-nameremark {
  margin-top: 0;
}
.trade-list .list-body .item-multiple .data {
  padding: 0 8px;
}
.trade-list .list-body .item-multiple .data .prices {
  display: flex;
  position: unset;
  margin-bottom: 0;
}
.trade-list .list-body .item-multiple .data .prices .price {
  margin-right: 0.2rem;
}
.trade-list .list-body .item-multiple:nth-child(2n) {
  margin-right: 0;
}
.trade-list .list-filter {
  position: fixed;
  z-index: 777;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.trade-list .list-filter .mask {
  position: fixed;
  z-index: 888;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
  opacity: 0;
}
.trade-list .list-filter .container {
  position: relative;
  z-index: 999;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.trade-list .list-filter.enter-active .mask,
.trade-list .list-filter.enter-done .mask {
  opacity: 1;
}
.trade-list .list-filter.enter-active .container,
.trade-list .list-filter.enter-done .container {
  opacity: 1;
  transform: translateY(0);
}
.trade-list .list-filter.exit-active .mask {
  opacity: 0;
}
.trade-list .list-filter.exit-active .container {
  opacity: 0;
  transform: translateY(100%);
}
.trade-list .list-filter .title {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  margin: 10px auto;
}
.trade-list .list-filter .type {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.trade-list .list-filter .type .item {
  width: 65px;
  text-align: center;
  padding: 5px 0;
  background-color: #eee;
  color: #000;
  font-size: 0.28rem;
  border-radius: 4px;
  flex: 0 0 calc(calc(100% - 30px) / 4);
}
.trade-list .list-filter .type .item.active {
  background-color: var(--MainColor);
  color: #fff;
}
.trade-list .list-filter .type .item:nth-child(2n),
.trade-list .list-filter .type .item:nth-child(3) {
  margin-left: 10px;
}
.trade-list .list-filter .type .item:nth-child(1),
.trade-list .list-filter .type .item:nth-child(2),
.trade-list .list-filter .type .item:nth-child(3),
.trade-list .list-filter .type .item:nth-child(4) {
  margin-bottom: 10px;
}
.trade-list .list-filter .prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade-list .list-filter .prices input {
  border: 1px solid #eee;
  padding: 5px 0;
  color: #000;
  border-radius: 4px;
  text-align: center;
}
.trade-list .list-filter .tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
}
.trade-list .list-filter .tag .item {
  flex: 0 0 25%;
}
.trade-list .list-filter .tag .item span {
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  padding: 5px 0;
  background-color: #ccc;
  color: #000;
  font-size: 0.28rem;
  border-radius: 4px;
}
.trade-list .list-filter .tag .item.active span {
  background-color: var(--MainColor);
  color: #fff;
}
.trade-list .list-filter .operate {
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.trade-list .list-filter .operate .btn {
  flex: 1;
  background-color: var(--MainColor);
  border: 1px solid var(--MainColor);
  color: #fff;
  border-radius: 20px;
  padding: 7.5px 0;
  text-align: center;
}
.trade-list .list-filter .operate .btn:first-child {
  margin-right: 10px;
  background-color: #fff;
  color: var(--MainColor);
}
.trade-notice .notice-info {
  background-color: #ffc000;
  color: #fff;
  font-size: 0.26rem;
  padding: calc(env(safe-area-inset-top) + 10px + 40px) 10px 10px 10px;
}
.trade-notice .notice-process {
  padding-top: 10px;
  border-bottom: 10px solid #f5f6f8;
}
.trade-notice .notice-process:last-child {
  border-bottom: 0;
}
.trade-notice .notice-process .title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.28rem;
  padding: 0 10px;
}
.trade-notice .notice-process .title img {
  height: 20px;
  margin-right: 5px;
}
.trade-notice .notice-process .process {
  padding: 10px 0;
}
.trade-notice .notice-process .process img {
  display: block;
  width: 85%;
  margin: 0 auto;
}
.trade-notice .notice-process .process ul {
  margin-top: 10px;
}
.trade-notice .notice-process .process ul li {
  float: left;
  width: 25%;
  text-align: center;
}
.trade-notice .notice-process .body {
  padding: 10px;
  overflow: hidden;
}
.trade-notice .notice-process .body p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
}
.trade-notice .notice-process .body p:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffc000;
  border-radius: 50%;
  position: absolute;
  top: 30%;
  left: 0;
}
.trade-good {
  background-color: #f8f8f8;
  padding: 10px;
}
.trade-good .good-img {
  padding: 0 10px;
}
.trade-good .good-img img {
  display: block;
  margin: 0 auto;
}
.trade-submit .trade-body {
  padding: 10px;
}
.trade-submit .trade-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.trade-submit .trade-input.develop {
  flex-direction: column;
  align-items: flex-start;
}
.trade-submit .trade-input .name {
  flex: 1;
  width: 0;
  font-size: 0.28rem;
  color: #333;
}
.trade-submit .trade-input .value {
  overflow: hidden;
  position: relative;
}
.trade-submit .trade-input.develop .name {
  width: 100%;
}
.trade-submit .trade-input.develop .value {
  width: 100%;
  margin-top: 5px;
}
.trade-submit .trade-input select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.trade-submit .trade-input input {
  width: 100%;
  background: none;
  border: none;
  text-align: right;
  padding: 5px;
}
.trade-submit .trade-input textarea {
  width: 100%;
  padding: 5px;
  background: none;
  border: none;
}
.trade-submit .trade-input.develop input {
  text-align: left;
}
.trade-submit .trade-tips {
  font-size: 13px;
  margin-bottom: 50px;
}
.trade-submit .trade-tips p {
  margin-top: 10px;
}
.trade-submit .trade-tips span {
  color: var(--MainColor);
}
.trade-submit .trade-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 7.5px 10px env(safe-area-inset-bottom) 10px;
  display: flex;
  align-items: center;
  z-index: 99;
}
.trade-submit .trade-operate .btn {
  flex: 1;
  background-color: var(--MainColor);
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.trade-record .record-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.trade-record .record-head .item {
  flex: 1;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: #333;
}
.trade-record .record-head .item:after {
  content: '';
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: var(--MainColor);
}
.trade-record .record-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.trade-record .record-head .item.active:after {
  display: block;
}
.trade-record .record-body {
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
  background-color: #f8f8f8;
  min-height: 100vh;
}
.trade-record .record-body .type-item {
  display: none;
}
.trade-record .record-body .type-item.active {
  display: block;
}
.trade-record .record-body .type-item-submit {
  padding-top: 30px;
}
.trade-record .record-body .type-item .am-swipe-btn {
  color: #fff;
  background-color: red;
}
.trade-record .record-body .type-item .items .item {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
}
.trade-record .record-body .type-item .items .body {
  background-color: #fff;
  padding: 10px;
  flex: 1;
  transition: 0.1s;
}
.trade-record .record-body .type-item .items .operate {
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  max-width: 0;
  padding: 0;
  opacity: 0;
}
.trade-record .record-body .type-item .items .operable .operate {
  padding: 15px;
  opacity: 1;
  max-width: 40px;
}
.trade-record .record-body .type-item .items .main {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .main .icon {
  width: 75px;
  height: 55px;
  margin-right: 10px;
}
.trade-record .record-body .type-item .items .main .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.trade-record .record-body .type-item .items .main .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.trade-record .record-body .type-item .items .main .info .name {
  font-size: 0.3rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.trade-record .record-body .type-item .items .main .info .name .type {
  background-color: #ffc000;
  border-radius: 2px;
  padding: 0px 3px;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
}
.trade-record .record-body .type-item .items .main .info .name .type-1:after {
  content: 'BT版';
}
.trade-record .record-body .type-item .items .main .info .name .type-2:after {
  content: '折扣版';
}
.trade-record .record-body .type-item .items .main .info .name .type-3:after {
  content: 'H5版';
}
.trade-record .record-body .type-item .items .main .info .name .type-4:after {
  content: 'GM版';
}
.trade-record .record-body .type-item .items .main .info .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.1rem;
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .main .info .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  font-weight: normal;
}
.trade-record .record-body .type-item .items .main .info .name .gamename {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-record .record-body .type-item .items .main .info .name .original {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .main .info .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: bold;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .main .info .title {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trade-record .record-body .type-item .items .main .info .title .ios {
  font-size: 16px;
  color: #666666;
  margin-right: 2.5px;
}
.trade-record .record-body .type-item .items .main .info .title .android {
  font-size: 16px;
  color: green;
  margin-right: 2.5px;
}
.trade-record .record-body .type-item .items .data {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .data .time {
  flex: 1;
  width: 0;
  color: #ccc;
  font-size: 12px;
  padding-top: 5px;
}
.trade-record .record-body .type-item .items .data .prices {
  display: flex;
  align-items: center;
}
.trade-record .record-body .type-item .items .data .prices .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  margin-right: 10px;
}
.trade-record .record-body .type-item .items .data .prices .price {
  color: #FFA21C;
  font-size: 0.3rem;
}
.trade-record .record-tips {
  position: fixed;
  z-index: 333;
  top: calc(env(safe-area-inset-top) + 40px + 45px);
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 0 5px;
  text-align: center;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
