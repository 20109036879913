.faq-index .faq-item {
  padding: 0 15px;
}
.faq-index .faq-item .q {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EDEDED;
}
.faq-index .faq-item .q .text {
  font-size: 15px;
  color: #282828;
  padding: 10px 0;
}
.faq-index .faq-item .a {
  font-size: 14px;
  color: #666;
  padding: 15px 0;
}
.faq-index dl {
  padding: 5px;
}
.faq-index dt {
  background-size: 0.237rem auto;
  font-size: 0.32rem;
  color: #282828;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.faq-index dt .icon {
  margin-right: 5px;
  color: #fe8900;
}
