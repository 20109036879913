:root {
  --MainColor: #eb424c;
}
.account-login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 0;
  position: relative;
}
.account-login .body {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.account-login .body .logo {
  padding-bottom: 15px;
}
.account-login .body .logo img {
  display: block;
  width: 25%;
  margin: 0 auto;
  border-radius: 22px;
}
.account-login .body .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
}
.account-login .body .input-item .label {
  width: 50px;
  margin-right: 10px;
  font-size: 15px;
  color: #666;
}
.account-login .body .input-item .input {
  flex: 1 1;
  width: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-login .body .input-item .input input {
  border: none;
  flex: 1;
  font-size: 16px;
  padding: 5px;
  color: #999;
}
.account-login .body .input-item .input .assist {
  padding: 5px;
}
.account-login .body .input-item .input .select {
  margin-left: 5px;
  padding: 5px;
  position: relative;
}
.account-login .body .input-item .input .select select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
}
.account-login .body .operate {
  width: 100%;
}
.account-login .body .operate .item {
  float: left;
  padding: 15px 0 30px;
}
.account-login .body .operate .item:first-child {
  color: var(--MainColor);
}
.account-login .body .operate .item:last-child {
  float: right;
  color: var(--MainColor);
}
.account-login .body .btn {
  background-color: var(--MainColor);
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-login .body .btn.disable {
  background-color: #ccc;
}
.account-login .body .btn-phone {
  color: #000;
  text-align: center;
  margin-top: 12.5px;
  font-size: 0.28rem;
}
.account-phonelogin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-phonelogin .phonelogin-body {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.account-phonelogin .phonelogin-body .logo {
  padding-bottom: 15px;
}
.account-phonelogin .phonelogin-body .logo img {
  display: block;
  width: 50%;
  margin: 0 auto;
}
.account-phonelogin .phonelogin-body .body {
  margin: 50px 0;
}
.account-phonelogin .phonelogin-body .input {
  padding: 5px;
  border: 1px solid #ffc000;
  border-radius: 8px;
  display: flex;
}
.account-phonelogin .phonelogin-body .input input {
  flex: 1;
  width: 0;
  text-align: center;
  padding: 7.5px;
  border: none;
  font-size: 0.32rem;
  color: #ffc000;
}
.account-phonelogin .phonelogin-body .code {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.account-phonelogin .phonelogin-body .code input {
  flex: 1;
  padding: 7.5px 10px;
  border: 1px solid #ffc000;
  border-radius: 8px;
  font-size: 0.28rem;
}
.account-phonelogin .phonelogin-body .code-btn {
  background-color: #ffc000;
  border-radius: 8px;
  padding: 7.5px;
  font-size: 0.26rem;
  color: #fff;
  margin-left: 12.5px;
}
.account-phonelogin .phonelogin-body .code-btn.disable {
  background-color: #ccc;
}
.account-phonelogin .phonelogin-body .btn {
  background-color: #ffc000;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-phonelogin .phonelogin-body .btn.disable {
  background-color: #ccc;
}
.account-phonelogin .phonelogin-body .btn-phone {
  color: #ffc000;
  text-align: center;
  margin-top: 12.5px;
  font-size: 0.28rem;
}
.account-phonelogin .phonelogin-users {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.account-phonelogin .phonelogin-users .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.account-phonelogin .phonelogin-users .body {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-phonelogin .phonelogin-users .title {
  font-size: 0.3rem;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0;
}
.account-phonelogin .phonelogin-users .users {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  max-height: 33vh;
}
.account-phonelogin .phonelogin-users .users .user {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #eee;
  font-size: 0.28rem;
}
.account-register {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 999;
  position: relative;
}
.account-register-btn {
  color: #F5842D;
}
.account-register .body {
  flex: 1 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.account-register .body .logo {
  padding-bottom: 15px;
}
.account-register .body .logo img {
  display: block;
  width: 25%;
  margin: 0 auto;
  border-radius: 22px;
}
.account-register .body .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
}
.account-register .body .input-item .label {
  width: 50px;
  margin-right: 10px;
  font-size: 15px;
  color: #666;
}
.account-register .body .input-item .input {
  flex: 1 1;
  width: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-register .body .input-item .input input {
  border: none;
  flex: 1;
  font-size: 16px;
  color: #999;
  padding: 7.5px;
}
.account-register .body .input-item .input .assist {
  padding: 5px;
}
.account-register .body .input-item .input .select {
  margin-left: 5px;
  padding: 5px;
  position: relative;
}
.account-register .body .input-item .input .select select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
}
.account-register .body .btn {
  background-color: var(--MainColor);
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
  margin-top: 75px;
}
.account-register .body .btn.disable {
  background-color: #ccc;
}
.account-agreement {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.account-agreement .agreement {
  margin-right: 5px;
  border: 2px solid #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2.5px;
  position: relative;
}
.account-agreement .agreement.checked {
  border: 2px solid var(--MainColor);
}
.account-agreement .agreement.checked:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--MainColor);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.account-agreement a {
  color: var(--MainColor);
  text-align: center;
}
.account-phone {
  padding: 10px;
}
.account-phone .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.account-phone .input-item .label {
  width: 50px;
  margin-right: 10px;
  color: #333;
  font-size: 15px;
}
.account-phone .input-item .input {
  flex: 1;
  width: 0;
  border: 1px solid #eee;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-phone .input-item .input input {
  width: 100%;
  padding: 7.5px;
  border: none;
  font-size: 16px;
  color: #333;
}
.account-phone .input-item .input .assist {
  padding: 5px;
}
.account-phone .input-item .btn {
  margin-left: 10px;
  background-color: var(--MainColor);
  border-radius: 8px;
  padding: 5px 7.5px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.account-phone .input-item .btn.disable {
  background-color: #ccc;
}
.account-phone .operate {
  background-color: var(--MainColor);
  border-radius: 20px;
  padding: 7.5px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.account-phone .operate.disable {
  background-color: #ccc;
}
.account-forget {
  padding: 0 20px;
}
.account-forget .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.account-forget .input-item .label {
  width: 50px;
  margin-right: 10px;
  font-size: 15px;
  color: #333;
}
.account-forget .input-item .label .area-num-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.account-forget .input-item .label .area-num-text a {
  padding-right: 0.05rem;
}
.account-forget .input-item .label .area-num-text img {
  width: 8px;
  height: 8px;
}
.account-forget .input-item .input {
  flex: 1;
  width: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-forget .input-item .input input {
  width: 100%;
  padding: 7.5px;
  border: none;
  font-size: 16px;
  color: #333;
}
.account-forget .input-item .input .assist {
  padding: 5px;
}
.account-forget .input-item .btn {
  margin-left: 10px;
  border-radius: 14px;
  padding: 5px 7.5px;
  font-size: 12px;
  color: var(--MainColor);
  text-align: center;
}
.account-forget .input-item .btn.disable {
  background-color: #ccc;
}
.account-forget .operate {
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 50px;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.account-forget .operate.disable {
  background-color: #ccc;
}
.account-verificode-login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  max-width: 100%;
}
.account-verificode-login .verificode-body {
  flex: 1 1;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.account-verificode-login .verificode-body .logo {
  padding-bottom: 30px;
}
.account-verificode-login .verificode-body .logo img {
  display: block;
  width: 25%;
  margin: 0 auto;
  border-radius: 22px;
}
.account-verificode-login .verificode-body .toggle {
  margin-top: 15px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 0 3em;
  font-size: 16px;
}
.account-verificode-login .verificode-body .toggle .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-verificode-login .verificode-body .toggle .active {
  font-weight: bold;
}
.account-verificode-login .verificode-body .toggle .active::after {
  content: '';
  background-color: var(--MainColor);
  width: 20px;
  height: 2px;
  margin-top: 5px;
}
.account-verificode-login .verificode-body .register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px 30px;
}
.account-verificode-login .verificode-body .register a {
  color: var(--MainColor);
  font-size: 13px;
}
.account-verificode-login .verificode-body .register a:last-child {
  color: #999;
}
.account-verificode-login .verificode-body .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
}
.account-verificode-login .verificode-body .input-item .label {
  width: 50px;
  margin-right: 10px;
  font-size: 15px;
  color: #666;
}
.account-verificode-login .verificode-body .input-item .label .area-num-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.account-verificode-login .verificode-body .input-item .label .area-num-text a {
  padding-right: 0.05rem;
}
.account-verificode-login .verificode-body .input-item .label .area-num-text img {
  width: 8px;
  height: 8px;
}
.account-verificode-login .verificode-body .input-item .input {
  flex: 1 1;
  width: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account-verificode-login .verificode-body .input-item .input input {
  width: 100%;
  padding: 7.5px;
  border: none;
  font-size: 15px;
  color: #999;
}
.account-verificode-login .verificode-body .input-item .input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.account-verificode-login .verificode-body .input-item .input .assist {
  padding: 5px;
}
.account-verificode-login .verificode-body .input-item .input .select {
  margin-left: 5px;
  padding: 4.5px;
  position: relative;
}
.account-verificode-login .verificode-body .input-item .input .select select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
}
.account-verificode-login .verificode-body .input-item .input input::-webkit-outer-spin-button,
.account-verificode-login .verificode-body .input-item .input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.account-verificode-login .verificode-body .input-item .code-btn {
  margin-left: 10px;
  border-radius: 8px;
  padding: 5px 7.5px;
  font-size: 12px;
  color: var(--MainColor);
  text-align: center;
}
.account-verificode-login .verificode-body .login-desc {
  text-align: center;
  font-size: 13px;
  color: #999;
  padding: 15px 30px 30px;
}
.account-verificode-login .verificode-body .operate {
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.account-verificode-login .verificodelogin-users {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.account-verificode-login .verificodelogin-users .mask {
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.account-verificode-login .verificodelogin-users .body {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
}
.account-verificode-login .verificodelogin-users .title {
  font-size: 0.3rem;
  color: #000;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0;
}
.account-verificode-login .verificodelogin-users .users {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  max-height: 33vh;
}
.account-verificode-login .verificodelogin-users .users .user {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #eee;
  font-size: 0.28rem;
}
.account-verificode-login .login-alert {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.account-verificode-login .login-alert .login-mask {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.account-verificode-login .login-alert .login-container {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  height: 380px;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.account-verificode-login .login-alert.appear-active .login-mask,
.account-verificode-login .login-alert.appear-done .login-mask,
.account-verificode-login .login-alert.enter-active .login-mask,
.account-verificode-login .login-alert.enter-done .login-mask {
  opacity: 1;
}
.account-verificode-login .login-alert.appear-active .login-container,
.account-verificode-login .login-alert.appear-done .login-container,
.account-verificode-login .login-alert.enter-active .login-container,
.account-verificode-login .login-alert.enter-done .login-container {
  opacity: 1;
  transform: translateY(0);
}
.account-verificode-login .login-alert.exit-active .gift-mask {
  opacity: 0;
}
.account-verificode-login .login-alert.exit-active .login-container {
  opacity: 0;
  transform: translateY(100%);
}
.account-verificode-login .login-alert.exit-done {
  display: none;
}
.account-verificode-login .login-alert .login-top {
  font-size: 0.36rem;
  color: #282828;
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
}
.account-verificode-login .login-alert .login-top .close {
  width: 32px;
  height: 32px;
  padding: 10px;
  position: absolute;
  top: 15px;
  right: 10px;
}
.account-verificode-login .login-alert .login-main .login-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
}
.account-verificode-login .login-alert .login-main .login-item .title {
  font-size: 0.3rem;
  color: #282828;
}
.account-verificode-login .login-alert .login-main .login-item img {
  width: 17px;
}
.account-verificode-login .login-alert .login-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  padding: 20px 0;
  margin-top: 20px;
}
.account-verificode-login .login-alert .login-bottom input {
  border: none;
  font-size: 0.28rem;
  padding: 2px 0;
  flex: 1;
  color: #282828;
}
.account-verificode-login .login-alert .login-bottom .code {
  color: var(--MainColor);
  font-size: 0.28rem;
}
.account-verificode-login .login-alert .login-submit {
  margin-top: 56px;
  padding: 10px 0;
  border-radius: 25px;
  font-size: 0.36rem;
  text-align: center;
  color: white;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: calc(env(safe-area-inset-bottom) + 20px);
  background-color: var(--MainColor);
}
