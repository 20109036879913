.hangup-index {
  background-color: #F5F6F8;
  min-height: calc(100vh - 40px - env(safe-area-inset-top));
  overflow: hidden;
}
.hangup-index .hangup-container .hangup-chat {
  padding: 15px 15px 0;
}
.hangup-index .hangup-container .hangup-chat .message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
}
.hangup-index .hangup-container .hangup-chat .message .adm-swiper-item {
  display: flex;
  align-items: center;
}
.hangup-index .hangup-container .hangup-chat .message img {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  vertical-align: middle;
}
.hangup-index .hangup-container .hangup-chat .message .content {
  flex: 1;
  font-size: 0.22rem;
  color: #282828;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
}
.hangup-index .hangup-container .hangup-chat .message .join {
  font-size: 0.24rem;
  color: #999;
  flex-shrink: 0;
}
.hangup-index .hangup-container .hangup-container-content {
  position: relative;
}
.hangup-index .hangup-container .hangup-own {
  position: relative;
  margin: 0 20px;
  margin-top: 15px;
  background: linear-gradient(#E9EAED, #D9DBE1, #D8D9DC);
  border-radius: 13px;
  height: 65vh;
  overflow: hidden;
}
.hangup-index .hangup-container .hangup-own .device-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hangup-index .hangup-container .hangup-own .device-info {
  padding: 25px 0 0 22px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  padding-bottom: 20px;
}
.hangup-index .hangup-container .hangup-own .device-info .device-idle {
  color: #282828;
}
.hangup-index .hangup-container .hangup-own .device-info .device-hangup {
  color: white;
}
.hangup-index .hangup-container .hangup-own .device-info .device-name {
  font-size: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.hangup-index .hangup-container .hangup-own .device-info .device-name .editIcon {
  padding-left: 10px;
  height: 16px;
}
.hangup-index .hangup-container .hangup-own .device-info .device-time {
  font-size: 13px;
  font-weight: 500;
  padding: 3px 0;
}
.hangup-index .hangup-container .hangup-own .device-info .device-state {
  background-color: #000000;
  padding: 3px 12px;
  border-radius: 13px;
  font-size: 13px;
  margin-top: 10px;
  display: inline-block;
}
.hangup-index .hangup-container .hangup-own .device-info .device-state-idle {
  color: #65CF94;
}
.hangup-index .hangup-container .hangup-own .device-info .device-state-hangup {
  color: #FF5E00;
}
.hangup-index .hangup-container .hangup-own .device-game {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hangup-index .hangup-container .hangup-own .device-game img {
  height: 55px;
}
.hangup-index .hangup-container .hangup-own .device-game .title {
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 6px 0 8px 0;
}
.hangup-index .hangup-container .hangup-own .device-game .desc {
  color: white;
  font-size: 15px;
}
.hangup-index .hangup-container .hangup-own .device-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
}
.hangup-index .hangup-container .hangup-own .device-action .action-item {
  padding: 15px;
  font-size: 14px;
  color: #282828;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hangup-index .hangup-container .hangup-own .device-action .action-item img {
  height: 17px;
  padding-right: 5px;
}
.hangup-index .hangup-container .hangup-own .action-item:first-child::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #F5F6F8;
  width: 1px;
  height: 30px;
  display: inline-block;
}
.hangup-index .hangup-container .hangup-own .action-item:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -15%;
  background-color: #F5F6F8;
  width: 1px;
  height: 30px;
  display: inline-block;
}
.hangup-index .hangup-container .hangup-own .question {
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 2;
}
.hangup-index .hangup-container .hangup-own .question img {
  height: 23px;
}
.hangup-index .hangup-container .device-buy {
  background-color: var(--MainColor);
  padding: 11px;
  margin: 0 42px;
  border-radius: 25px;
  color: white;
  margin-top: 35px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hangup-index .hangup-container .device-buy img {
  width: 15px;
  margin-right: 10px;
}
.hangup-index .hangup-container .device-list {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.hangup-index .hangup-container .device-list .device-list-container {
  position: absolute;
  top: calc(env(safe-area-inset-top) + 50px);
  right: 15px;
  background-color: white;
  border-radius: 8px;
  padding: 18px;
  z-index: 2;
  overflow: scroll;
  max-height: 300px;
}
.hangup-index .hangup-container .device-list .device-list-container .device-list-item {
  padding: 8px;
  color: #282828;
  font-size: 13px;
}
.hangup-index .hangup-container .hangup-noown {
  margin: 0 20px;
  margin-top: 15px;
  background: linear-gradient(#E9EAED, #D9DBE1, #D8D9DC);
  border-radius: 13px;
  text-align: center;
  padding-top: 60px;
}
.hangup-index .hangup-container .hangup-noown .title {
  font-size: 20px;
  color: #282828;
  font-weight: 500;
}
.hangup-index .hangup-container .hangup-noown .desc {
  color: #282828;
  font-size: 12px;
  padding-top: 12px;
}
.hangup-index .hangup-container .hangup-noown .func {
  padding-top: 35px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hangup-index .hangup-container .hangup-noown .func .text {
  flex-shrink: 0;
  padding: 0 8px;
  color: #282828;
  font-size: 12px;
}
.hangup-index .hangup-container .hangup-noown .func img {
  height: 13px;
}
.hangup-index .hangup-container .hangup-noown .item {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: #FFFFFF55;
  border-radius: 13px;
  margin: 0 22px;
  margin-top: 10px;
}
.hangup-index .hangup-container .hangup-noown .item .icon img {
  height: 38px;
}
.hangup-index .hangup-container .hangup-noown .item .content {
  text-align: left;
  padding-left: 20px;
}
.hangup-index .hangup-container .hangup-noown .item .content .title {
  font-size: 17px;
  color: #282828;
  font-weight: 500;
}
.hangup-index .hangup-container .hangup-noown .item .content .sub {
  font-size: 12px;
  color: #666;
}
.hangup-index .hangup-container .hangup-noown .use {
  background-color: var(--MainColor);
  border-radius: 25px;
  margin: 55px 22px 0 22px;
  padding: 15px;
  font-size: 16px;
  color: white;
  font-weight: 500;
  display: block;
}
.hangup-index .hangup-container .hangup-noown .turo {
  padding: 20px 0 36px 0;
  display: block;
}
.hangup-index .hangup-container .hangup-noown .turo span {
  font-size: 12px;
  color: #666;
  padding-right: 7px;
}
.hangup-index .hangup-container .hangup-noown .turo img {
  height: 9px;
}
.hangup-index .use-alert {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.hangup-index .use-alert .use-mask {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
  opacity: 0;
}
.hangup-index .use-alert .use-container {
  position: relative;
  z-index: 666;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.hangup-index .use-alert .use-container .title {
  font-size: 19px;
  color: #282828;
  font-weight: 500;
  text-align: center;
}
.hangup-index .use-alert .use-container .content {
  padding: 25px 45px;
  color: #666;
  font-size: 14px;
}
.hangup-index .use-alert .use-container .input {
  border: none;
  background-color: #F6F6F6;
  margin: 20px 30px;
  padding: 15px;
  border-radius: 8px;
  font-size: 15px;
  color: #282828;
  margin-bottom: 30px;
}
.hangup-index .use-alert .use-container .submit {
  background-color: var(--MainColor);
  border-radius: 25px;
  font-size: 18px;
  color: white;
  font-weight: 400;
  text-align: center;
  padding: 12px;
  margin: 0 45px;
}
.hangup-index .use-alert .use-container .cancel {
  color: #666;
  font-size: 14px;
  text-align: center;
  padding: 9px;
  margin-bottom: 10px;
}
.hangup-index .use-alert .use-container .search {
  margin: 15px 12px;
  padding: 10px 0;
  border-radius: 21px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hangup-index .use-alert .use-container .search .searchbar {
  border: none;
  color: #282828;
  font-size: 14px;
  flex: 1;
  padding-left: 15px;
}
.hangup-index .use-alert .use-container .search .search-delete {
  height: 17px;
  padding: 0 10px;
}
.hangup-index .use-alert .use-container .search .search-submit {
  color: var(--MainColor);
  font-size: 15px;
  padding: 0 15px;
  border-left: solid 1px #F1F1F1;
}
.hangup-index .use-alert .use-container .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 25%;
}
.hangup-index .use-alert .use-container .empty .icon {
  width: 200px;
  padding-bottom: 25px;
}
.hangup-index .use-alert .use-container .empty .desc {
  font-size: 14px;
  color: #666;
}
.hangup-index .use-alert .use-container .list {
  overflow: scroll;
}
.hangup-index .use-alert .use-container .list .adm-list {
  --border-bottom: none;
  --border-top: none;
}
.hangup-index .use-alert .use-container .list .adm-list-body {
  background-color: transparent;
}
.hangup-index .use-alert .use-container .list .adm-list-body .adm-list-item-content-arrow {
  font-size: 0;
}
.hangup-index .use-alert .use-container .list .gameitem {
  background-color: white;
  border-radius: 13px;
  align-items: center;
  margin-bottom: 10px;
  padding: 0;
}
.hangup-index .use-alert .use-container .list .gameitem .adm-list-item-content {
  padding: 0;
  border: none;
}
.hangup-index .use-alert .use-container .list .gameitem .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  padding: 0;
}
.hangup-index .use-alert .use-container .list .gameitem .icon {
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.hangup-index .use-alert .use-container .list .gameitem .icon img {
  height: 54px;
  width: 54px;
}
.hangup-index .use-alert .use-container .list .gameitem .content {
  flex: 1;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hangup-index .use-alert .use-container .list .gameitem .content .info {
  flex: 1;
}
.hangup-index .use-alert .use-container .list .gameitem .content .info .name {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.hangup-index .use-alert .use-container .list .gameitem .content .info .name .game-name {
  font-weight: 500;
  font-size: 15px;
  color: #282828;
  flex-shrink: 0;
}
.hangup-index .use-alert .use-container .list .gameitem .content .info .name .name-remark {
  margin-left: 0.1rem;
  font-size: 11px;
  color: #999;
  background-color: #f3f4f8;
  padding: 1px 4px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hangup-index .use-alert .use-container .list .gameitem .content .info .type {
  font-size: 12px;
  color: #999;
  padding-top: 3px;
}
.hangup-index .use-alert .use-container .list .gameitem .content .action {
  font-size: 12px;
  padding: 3px 6px;
  margin-right: 10px;
}
.hangup-index .use-alert .use-container .list .gameitem .content .notsupport {
  color: #D4D4D4;
  border: none;
}
.hangup-index .use-alert .use-container .list .gameitem .content .support {
  color: var(--MainColor);
  border: 1px solid var(--MainColor);
  border-radius: 13px;
}
.hangup-index .use-alert.appear-active .use-mask,
.hangup-index .use-alert.appear-done .use-mask,
.hangup-index .use-alert.enter-active .use-mask,
.hangup-index .use-alert.enter-done .use-mask {
  opacity: 1;
}
.hangup-index .use-alert.appear-active .use-container,
.hangup-index .use-alert.appear-done .use-container,
.hangup-index .use-alert.enter-active .use-container,
.hangup-index .use-alert.enter-done .use-container {
  opacity: 1;
  transform: translateY(0);
}
.hangup-index .use-alert.appear-active .use-container .use-close,
.hangup-index .use-alert.appear-done .use-container .use-close,
.hangup-index .use-alert.enter-active .use-container .use-close,
.hangup-index .use-alert.enter-done .use-container .use-close {
  position: absolute;
  top: 5px;
  padding: 15px;
  right: 0;
}
.hangup-index .use-alert.exit-active .use-mask {
  opacity: 0;
}
.hangup-index .use-alert.exit-active .use-container {
  opacity: 0;
  transform: translateY(100%);
}
