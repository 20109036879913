:root {
  --MainColor: #eb424c;
}
.recovery-record {
  background-color: #F5F6F8;
}
.recovery-index {
  padding: 10px;
  background: #f5f6f8;
  min-height: calc(100vh - 40px);
}
.recovery-index .recovery-step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 8px;
}
.recovery-index .recovery-step .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 5px;
  color: #282828;
  font-size: 0.26rem;
  flex-direction: column;
  position: relative;
}
.recovery-index .recovery-step .item .icon {
  width: 35px;
  height: 35px;
}
.recovery-index .recovery-step .icon-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}
.recovery-index .recovery-explain,
.recovery-index .recovery-tips {
  margin-top: 5px;
}
.recovery-index .recovery-explain .title,
.recovery-index .recovery-tips .title {
  font-weight: bold;
  font-size: 0.34rem;
  color: #000;
  margin-bottom: 5px;
}
.recovery-index .recovery-explain .desc,
.recovery-index .recovery-tips .desc {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.recovery-index .recovery-explain .item,
.recovery-index .recovery-tips .item {
  position: relative;
  margin-top: 5px;
  font-size: 13px;
}
.recovery-index .recovery-explain .item:first-child,
.recovery-index .recovery-tips .item:first-child {
  margin-top: 0;
}
.recovery-index .recovery-title {
  margin-top: 15px;
  font-size: 0.34rem;
  display: flex;
  align-items: center;
  color: #000;
  font-weight: bold;
}
.recovery-index .recovery-title .icon {
  color: #ffc000;
  font-size: 0.3rem;
  margin-right: 5px;
}
.recovery-index .recovery-items .item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px 12px;
  border-radius: 8px;
}
.recovery-index .recovery-items .item .icon {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
}
.recovery-index .recovery-items .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-index .recovery-items .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
}
.recovery-index .recovery-items .item .info .name {
  color: #282828;
  font-size: 0.34rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  display: flex;
  align-items: center;
}
.recovery-index .recovery-items .item .info .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.1rem;
}
.recovery-index .recovery-items .item .info .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.3;
}
.recovery-index .recovery-items .item .info .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}
.recovery-index .recovery-items .item .info .desc {
  margin-top: 2.5px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  font-size: 12px;
}
.recovery-index .recovery-items .item .info .desc .coin {
  color: #FF5E00;
}
.recovery-index .recovery-items .item .info .desc .number {
  color: #666;
}
.recovery-index .recovery-items .item .price {
  padding-left: 5px;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  float: right;
}
.recovery-index .recovery-items .item .price span {
  color: #FF5E00;
  font-size: 0.28rem;
}
.recovery-record .record-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.recovery-record .record-head .item {
  flex: 1;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: #333;
}
.recovery-record .record-head .item:after {
  content: '';
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.recovery-record .record-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.recovery-record .record-head .item.active:after {
  display: block;
}
.recovery-record .record-body {
  margin-top: 45px;
  padding: 0px 15px 0;
  position: relative;
  min-height: calc(100vh - 40px - 45px - env(safe-area-inset-top));
}
.recovery-record .record-body .type-item {
  display: none;
  overflow: hidden;
}
.recovery-record .record-body .type-item.active {
  display: block;
}
.recovery-record .record-body .record-item {
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  position: relative;
  padding: 15px 10px;
  background-color: white;
  border-radius: 13px;
}
.recovery-record .record-body .record-item .icon {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 7.5px;
  flex-shrink: 0;
}
.recovery-record .record-body .record-item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-record .record-body .record-item .subject {
  flex: 1;
  overflow: hidden;
}
.recovery-record .record-body .record-item .subject .name {
  color: #282828;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.recovery-record .record-body .record-item .subject .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.1rem;
}
.recovery-record .record-body .record-item .subject .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.3;
}
.recovery-record .record-body .record-item .subject .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}
.recovery-record .record-body .record-item .subject .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .subject .info .desc {
  margin-top: 2.5px;
  color: #999;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .subject .info .desc span {
  color: #666;
}
.recovery-record .record-body .record-item .subject .operate {
  color: #999;
  font-size: 12px;
}
.recovery-record .record-body .record-item .subject .operate span {
  color: #FF5E00;
}
.recovery-record .record-body .record-item .price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-record .record-body .record-item .price span {
  color: red;
  font-size: 0.28rem;
}
.recovery-record .record-body .record-item .time {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-confirm {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.recovery-confirm .confirm-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  background: #fff center top no-repeat;
  background-size: contain;
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
  max-height: 75vh;
}
.recovery-confirm .confirm-head {
  color: #fff;
  padding: 30px 10px;
  text-align: center;
}
.recovery-confirm .confirm-head .title {
  font-weight: bold;
  font-size: 0.3rem;
}
.recovery-confirm .confirm-head .info {
  font-size: 0.26rem;
  margin-top: 5px;
}
.recovery-confirm .confirm-body {
  flex: 1;
  padding: 10px;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.recovery-confirm .confirm-body .title {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #000;
  font-weight: bold;
}
.recovery-confirm .confirm-body .title img {
  display: block;
  height: 20px;
  margin-right: 7.5px;
}
.recovery-confirm .confirm-body .content {
  flex: 1;
  margin-top: 10px;
  min-height: 30vh;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.recovery-confirm .confirm-body .content p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 13px;
  color: #333;
}
.recovery-confirm .confirm-body .content p:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffc000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.recovery-confirm .confirm-foot {
  display: flex;
  flex-direction: column;
}
.recovery-confirm .confirm-foot .input {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.recovery-confirm .confirm-foot .input input {
  margin-right: 10px;
}
.recovery-confirm .confirm-foot .input .agreement {
  margin-right: 5px;
  border: 2px solid #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.recovery-confirm .confirm-foot .input .agreement.checked:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #0075ff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.recovery-confirm .confirm-foot .btns {
  display: flex;
  padding: 0 7.5px;
  margin-top: 5px;
}
.recovery-confirm .confirm-foot .btns .btn {
  flex: 1;
  background-color: #ffc000;
  margin: 0 7.5px;
  border-radius: 20px;
  padding: 10px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
}
.recovery-confirm .confirm-foot .btns .btn:last-child {
  background-color: #eee;
}
.recovery-confirm .confirm-foot .btns .btn:last-child.confirm {
  background-color: #ffc000;
}
.recovery-detail {
  padding-bottom: 55px;
  padding-top: calc(85px + 40px);
}
.recovery-detail .recovery-info {
  position: fixed;
  z-index: 22;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 85px;
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}
.recovery-detail .recovery-info .icon {
  width: 65px;
  height: 65px;
  margin-right: 7.5px;
}
.recovery-detail .recovery-info .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.recovery-detail .recovery-info .info .name {
  color: #000;
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.recovery-detail .recovery-info .info .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.recovery-detail .recovery-info .info .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: bold;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.3;
}
.recovery-detail .recovery-info .info .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}
.recovery-detail .recovery-info .info .desc {
  font-size: 0.26rem;
  color: #666;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recovery-detail .recovery-info .info .desc span {
  color: red;
}
.recovery-detail .recovery-thead {
  position: fixed;
  z-index: 22;
  top: calc(env(safe-area-inset-top) + 40px + 85px);
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #eee;
}
.recovery-detail .recovery-thead .th {
  flex: 1;
  text-align: center;
}
.recovery-detail .recovery-tbody .tr {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
  align-items: center;
}
.recovery-detail .recovery-tbody .td {
  flex: 1;
  text-align: center;
}
.recovery-detail .recovery-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  height: 55px;
  padding: 0 15px;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recovery-detail .recovery-operate .btn {
  background-color: #eee;
  border-radius: 20px;
  padding: 8.5px;
  font-size: 0.26rem;
  color: #fff;
  text-align: center;
}
.recovery-detail .recovery-operate .btn.active {
  background-image: linear-gradient(to right, #ea3e4a, #ef564f, #f46b57, #f87d5f, #fc8f6a);
}
.recovery-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #999;
  margin-top: 50%;
}
.recovery-empty img {
  display: block;
  width: 50%;
}
.recovery-buyread {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 3s;
}
.recovery-buyread::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 901;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.25s;
  opacity: 1;
}
.recovery-buyread .container {
  position: relative;
  z-index: 902;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff center top no-repeat;
  background-size: contain;
  transition: all 0.25s;
  opacity: 1;
  transform: scale(1);
}
.recovery-buyread .head {
  color: #fff;
  padding: 35px;
  text-align: center;
}
.recovery-buyread .head .title {
  font-weight: bold;
  font-size: 0.3rem;
}
.recovery-buyread .head .info {
  font-size: 0.26rem;
  margin-top: 5px;
}
.recovery-buyread .main {
  flex: 1 1;
  height: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.recovery-buyread .main .title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 0.28rem;
}
.recovery-buyread .main .title img {
  height: 20px;
  margin-right: 5px;
}
.recovery-buyread .main .body {
  flex: 1 1;
  height: 0;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
.recovery-buyread .main .body p {
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
}
.recovery-buyread .main .body p:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffc000;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
.recovery-buyread .operate .input {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.recovery-buyread .operate .input input {
  margin-right: 10px;
}
.recovery-buyread .operate .input .agreement {
  margin-right: 5px;
  border: 2px solid #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1.5px;
  position: relative;
}
.recovery-buyread .operate .input .agreement.checked {
  border: 2px solid #ffc000;
}
.recovery-buyread .operate .input .agreement.checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffc000;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.recovery-buyread .operate .btns {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.recovery-buyread .operate .buy,
.recovery-buyread .operate .think {
  flex: 1 1;
  display: block;
  background-color: #ffc000;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  margin-bottom: 7.5px;
  transition: all 0.25s;
}
.recovery-buyread .operate .disable {
  background-color: #eee;
}
.recovery-buyread .operate .think {
  background-color: #eee;
}
