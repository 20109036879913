.game-trade {
  min-height: 100vh;
  background-color: #f5f6f8;
  padding: 0 15px;
}
.game-trade .top-icon {
  padding: 15px 0;
}
.game-trade .item {
  display: block;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px 12.5px;
  border-radius: 8px;
  margin: 0 15px 10px;
}
.game-trade .info .icon {
  margin-right: 10px;
  width: 130px;
  height: 75px;
  float: left;
  position: relative;
}
.game-trade .info .icon .coin {
  position: absolute;
  top: 0;
  right: 0;
  background: #FF6C6C;
  border-radius: 0px 8px 0px 8px;
  width: 1.25rem;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  padding: 0.03rem 0;
}
.game-trade .info .icon img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.game-trade .info .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.game-trade .info .text .name {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-trade .info .text .desc {
  margin-top: 5px;
}
.game-trade .info .text .desc .android {
  color: #75D100;
}
.game-trade .data {
  display: flex;
  align-items: center;
  position: relative;
  clear: both;
}
.game-trade .data .time {
  flex: 1;
  width: 0;
  color: #ccc;
  font-size: 12px;
  padding-top: 5px;
}
.game-trade .data .price {
  display: flex;
  align-items: center;
}
.game-trade .data .price .amount {
  text-decoration: line-through;
  color: #ccc;
  font-size: 12px;
  margin-right: 10px;
}
.game-trade .data .price .price {
  color: #FFA21C;
  font-size: 0.3rem;
}
